<template>
  <v-card class="card-shadow border-radius-xl">
    <common-loading :title="loading.title" :message="loading.message" :loading_dialog="loading.dialog"
      :show_loading="loading.show_loading" :show_confirm="loading.show_confirm" :confirm_function="loading.function"
      v-on:confirm="loading.dialog = false"></common-loading>
    <v-card-title class="justify-center py-2 px-4">
      <h5 class="font-weight-bold text-h5 text-grt mb-0">
        {{ $t("Transcript Import") }}
      </h5>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-title>
      <v-file-input v-model="selectedFiles" label="選擇PDF文件" multiple accept=".pdf,.mdata" :show-size="true" class="mx-2"
        :truncate-length="100" @change="handleFileChange"></v-file-input>

      <v-btn color="primary" @click="uploadFiles" class="mb-6 mx-2" :disabled="!selectedFiles.length">
        上傳文件
      </v-btn>
      <!-- add reset btn -->
      <v-btn color="default" @click="reset()" class="mb-6"> 重置 </v-btn>
    </v-card-title>
    <transcript v-if="isShowTranscript" :propTranscripts="transcripts" ref="transcript"></transcript>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { ImportState, TaskStatus, SocketEventType } from "@/definitions.js";
import CommonLoading from "@/views/Pages/General/Widgets/CommonLoading.vue";
import Transcript from "@/views/Pages/CRM/Widgets/Transcript.vue";
export default {
  name: "TranscriptImport",
  data() {
    return {
      headers: [
        { text: "名稱", value: "name", sortable: true },
        { text: "描述", value: "description", sortable: false },
        { text: "狀態", value: "status", sortable: true },
        { text: "最後更新", value: "update_time", sortable: false },
        { text: "操作", value: "actions", sortable: false },
      ],
      confirm: {
        dialog: false,
        title: null,
        message: null,
        function: null,
      },
      zones: [],
      totalZones: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["name"],
        sortDesc: [false],
      },
      data_loading: false,
      loading: {
        dialog: false,
        title: null,
        message: null,
        show_loading: true,
        show_confirm: false,
      },
      transcripts: [],
      selectedFiles: [],
      isPolling: false,
      ImportState: ImportState,
      SocketEventType: SocketEventType,
      status: ImportState.Init,
      socket: {
        isConnected: false,
        message: '',
        reconnectError: false,
      },
      last_parsed_filenames: null,
      urban_token: null,
      charge: 0,
    };
  },
  methods: {

  },
  mounted() {
    // Add WebSocket event listeners
    this.$connect();

  },
  beforeDestroy() {
    // Clean up WebSocket event listeners
    this.$disconnect();
  },
  components: {
    Transcript,
    CommonLoading,
  },

  watch: {
    options: {
      handler() {
        this.fetchDevelopmentZones();
      },
      deep: true,
    },
    socketMessage(newMessage) {
      if (newMessage) {
        this.receiveSocketData(newMessage);
      }
    }
  },
  computed: {

    isParsing() {
      return this.status === ImportState.Parsing;
    },
    isPaid() {
      return this.status === ImportState.Paid;
    },
    isProcessing() {
      return this.status === ImportState.Processing;
    },
    isParsed() {
      return this.status === ImportState.Parsed;
    },
    isCompleted() {
      return this.status === ImportState.Completed;
    },
    isShowTranscript() {
      return this.status >= ImportState.Paid;
    },
    isImporting() {
      return this.status === ImportState.Importing;
    },
    isConnected() {
      return this.$store.state.websocket.isConnected;
    },
    socketMessage() {
      return this.$store.state.websocket.message;
    }
  },
  methods: {
    ...mapGetters(["getToken"]),
    handleFileChange(files) {
      this.selectedFiles = files;
    },
    async uploadFiles() {
      if (!this.selectedFiles.length) return;

      this.status = ImportState.Parsing;

      const formData = new FormData();
      for (let i = 0; i < this.selectedFiles.length; i++) {
        formData.append("files", this.selectedFiles[i]);
      }
      this.loading = {
        dialog: true,
        title: "上傳中",
        message: "上傳中",
        show_loading: true,
        show_confirm: false,
      }
      try {
        await this.parseFile(formData);
        this.$emit("upload-complete");
      } catch (error) {
        console.error("Upload failed:", error);
        this.status = ImportState.Failed;
      } finally {
        console.log("finally");
      }
    },
    parseFile(formData) {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/plan/transcribe/parse/`;
      const currentObj = this;
      const token = this.getToken();
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };
      this.reset_transcript();
      this.progress_message = {
        title: this.$t("plan.import-pdf"),
        text: "謄本解析中...",
      };

      this.axios
        .post(url, formData, config)
        .then(async function (response) {

          let data = response.data;
          console.log("parseFile", data);
          currentObj.task_id = data.task_id;
          await currentObj.get_task_status(); // Start polling for task status
        })
        .catch(function (error) {
          console.log(error);
          currentObj.status = ImportState.Failed;
          currentObj.showMessage("解析失敗 請稍後再試");
        })
        .finally(() => { });
    },
    async get_task_status() {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/plan/transcribe/task_status/`;
      let retryCount = 0;
      const maxRetries = 150;
      const retryInterval = 4000; // 4 seconds
      this.isPolling = true;

      const checkStatus = async () => {
        if (!this.isPolling) {
          return true; // Stop polling if dialog is closed
        }

        try {
          const response = await this.axios.get(url, {
            params: { task_id: this.task_id },
            headers: {
              Authorization: this.getToken(),
            },
          });

          const { status, data, error_message } = response.data;

          if (status === TaskStatus.Success) {
            this.setData(data);
            return true;
          } else if (status === TaskStatus.Failed) {
            console.error("Task failed:", error_message);
            this.status = ImportState.Failed;
            this.showMessage("解析失敗 請稍後再試");
            return true;
          } else if (retryCount < maxRetries) {
            retryCount++;
            await new Promise((resolve) => setTimeout(resolve, retryInterval));
            return false;
          } else {
            this.status = ImportState.Failed;
            this.showMessage("解析超時 請稍後再試");
            return true;
          }
        } catch (error) {
          console.error("Error checking task status:", error);
          this.status = ImportState.Failed;
          this.showMessage("解析失敗 請稍後再試");
          return true;
        }
      };

      while (!(await checkStatus())) {
        // Continue polling until we get a final status or hit max retries
      }
      this.isPolling = false;
    },
    reset_transcript() {
      this.processed = 0;
    },
    showMessage(message) {
      // this.message = message;
      console.debug(message);
    },
    setData(data) {
      console.log("解析成功了");

      let results = data.results;
      this.transcripts = results;
      this.last_parsed_filenames = data.filename;
      this.urban_token = data.urban_token;
      this.charge = data.charge;
      this.loading.message = "謄本解析中...";
      // this.retrySendObj({
      //   action: SocketEventType.TranscriptParseStart,
      //   filename: this.last_parsed_filenames,
      //   urban_token: this.urban_token,
      //   task_id: this.task_id,
      // });
      this.onPay();

    },
    onPay() {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/plan/transcribe/withdraw/`;
      const currentObj = this;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.getToken(),
        },
      };
      let filenames = this.selectedFiles.map((file) => file.name).join(",");

      let data = {
        balance: this.charge,
        description: filenames,
        task_id: this.task_id,
      };
      this.axios
        .post(url, data, config)
        .then(function (response) {
          console.log(response);
          currentObj.onPaid();
        })
        .catch(function (error) {
          console.log(error);
          // get the error message
          if (error.response.data) {
            let message = error.response.data;
            console.log("error message", message.error);
            if (message.error == "Insufficient balance") {
              currentObj.showMessage("餘額不足");
            } else {
              console.log("error message", message.error);
              currentObj.showMessage(message.error);
            }
          } else {
            currentObj.showMessage("付款失敗");
          }
        });
    },
    onPaid() {
      console.log("pay it.", this.last_parsed_filenames);
      this.status = ImportState.Paid;
      // this.setSelectedRegistration();
      this.retrySendObj({
        action: SocketEventType.TranscriptParseStart,
        filename: this.last_parsed_filenames,
        urban_token: this.urban_token,
        task_id: this.task_id,
      });
    },
    // setSelectedRegistration() {
    //   this.$nextTick(() => {
    //     console.log("setSelectedRegistration");
    //     if (this.$refs.transcript != null) {
    //       // 確保在 DOM 更新後能正確獲取 transcript 引用
    //       if (this.transcripts.length > 0) {

    //         let lbkey = this.transcripts[0].lbkey;
    //         this.$refs.transcript.setSelectedRegistration(lbkey);
    //       }
    //     } else {
    //       console.log("Not set it");
    //     }
    //   });
    // },
    // ws
    retrySendObj(data, maxRetries = 3, delay = 10000) {
      let retries = 0;
      this.status = ImportState.Processing;
      const attempt = () => {
        if (this.$socket.readyState === WebSocket.OPEN) {
          this.$socket.sendObj(data);
          console.log("Message sent successfully");
        } else if (retries < maxRetries) {
          retries++;
          console.log(
            `WebSocket not ready. Retrying in ${delay / 1000
            } seconds... (Attempt ${retries}/${maxRetries})`
          );
          setTimeout(attempt, delay);
        } else {
          console.error(`Failed to send after ${maxRetries} attempts`);
        }
      };

      attempt();
    },
    receiveSocketData(message) {
      let info = message;
      if (info.action == SocketEventType.TranscriptParseEvent) {
        let data = info.data;
        this.$refs.transcript.updateOwnerData(data);
        this.processed += 1;
        let progress = (this.processed / this.regnos_count) * 100;

        progress = progress > 100 ? 100 : progress;
        this.uploadProgress = progress;
      } else if (info.action == SocketEventType.TranscriptParseEnd) {
        this.status = ImportState.Completed;
        this.createTranscript();
        this.loading.dialog = false;
      }
    },
    createTranscript() {
      const old_api = false;
      console.log("createTranscript ", this.transcripts, " old_api: ", old_api);
      
      let url = "";
      let data = {};
      if (old_api) {
        url = `${process.env.VUE_APP_SERVER_URL}/api/v1/transcripts/bulk-transcripts/`;
      } else {
        url = `${process.env.VUE_APP_SERVER_URL}/api/v1/plan/transcribe/bulk-transcripts/`;
      }
      const token = this.getToken();
      const currentObj = this;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };
      if (old_api) {
        data = {
          data: JSON.stringify(this.transcripts),
          import_user: true,
        };
      } else {
        data = {
          task_id: this.task_id,
        };
      }

      this.axios
        .post(url, data, config)
        .then((response) => {
          console.log(response.data);
          this.status = ImportState.Completed;
          currentObj.onImportTranscriptDone();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    reset() {
      this.transcripts = [];
      this.selectedFiles = [];
      this.last_parsed_filenames = null;
      this.urban_token = null;
      this.regnos_count = 0;
      this.processed = 0;
      this.uploadProgress = 0;
      this.status = ImportState.Idle;
      this.charge = 0;
      // call transcript reset
      if (this.$refs.transcript != null) {
        this.$refs.transcript.reset();
      }
    },
    onImportTranscriptDone() {
      console.log("onImportTranscriptDone");
      this.loading.dialog = true;
      this.loading.title = this.$t("Success");
      this.loading.message = "謄本匯入成功";
      this.loading.show_confirm = true;
      this.loading.show_loading = false;
      this.loading.function = () => {
        this.loading.dialog = false;
      };
    },
  }
};

</script>

<style scoped>
.row-height-50 ::v-deep tbody tr {
  height: 50px !important;
}
</style>
